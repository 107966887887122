import React, { useState } from 'react';
import styled from 'styled-components';
import Layout from 'components/Layout';
import { graphql, Link } from 'gatsby';
import { MdPlace } from 'react-icons/md';
import Seo from 'components/SEO';
import ArticleCard from 'components/ArticleCard';
import { Container, Section, Title, Grid, Cell, Button, Buttons } from 'ui';
import HomeForm from 'components/pro/HomeForm';
import Header from 'components/Header';
import headerPro from 'images/headerPro.svg';
import renseignement from 'images/renseignement.png';

const Cards = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% + 16px);
  margin-top: -16px;
`;

const Card = styled.div`
  border: 1px solid ${({ theme }) => theme.card.borderTertiary};
  background-color: ${({ theme }) => theme.card.backgroundTertiary};
  border-radius: 8px;
  color: ${({ theme }) => theme.card.textTertiary};
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.5;
  padding: ${({ theme }) => theme.spacing()} ${({ theme }) => theme.spacing(2)};
  flex: 1;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
`;

const Icon = styled.div`
  color: ${({ theme }) => theme.card.borderTertiary};
  padding-bottom: ${({ theme }) => theme.spacing()};

  img {
    max-width: 64px;
  }
`;

const IndexPage = ({ data }) => {
  const actualities = data?.allPrismicActuality?.edges ?? [];

  const [selectedHandicap, setSelectedHandicap] = useState();
  const [selectedIssue, setSelectedIssue] = useState();
  const [selectedSpeciality, setSelectedSpeciality] = useState();
  const [selectedTerm, setSelectedTerm] = useState('');

  const specialities = data?.allPrismicSpecialty?.edges ?? [];

  const handicaps = data?.allPrismicHandicap?.edges ?? [];
  const issues = data?.allPrismicIssue?.edges ?? [];

  const handleChange = ({ kind, label, value }) => {
    if (kind === 'speciality') {
      setSelectedSpeciality({ label, value });
      setSelectedHandicap();
      setSelectedIssue();
    }
    if (kind === 'handicap') {
      setSelectedHandicap({ label, value });
      setSelectedSpeciality();
      setSelectedIssue();
    }
    if (kind === 'issue') {
      setSelectedIssue({ label, value });
      setSelectedSpeciality();
      setSelectedHandicap();
    }
  };

  const handleUpdateTerm = e => {
    handleReset();
    setSelectedTerm(e.target.value);
  };

  const handleReset = () => {
    setSelectedSpeciality();
    setSelectedHandicap();
    setSelectedIssue();
  };

  return (
    <Layout variant="pro">
      <Seo title="Accès professionnels de santé" />
      <Header
        title="Accès professionnels de santé"
        icon={headerPro}
        variant="withLogo"
        app="pro"
      />

      <Section>
        <Container>
          <Title>Que recherchez-vous ?</Title>
          <Grid>
            <Cell sizes={{ default: 1, desktop: 2 / 3 }}>
              <HomeForm
                specialities={specialities}
                handicaps={handicaps}
                issues={issues}
                onChange={handleChange}
                selectedHandicap={selectedHandicap}
                selectedIssue={selectedIssue}
                selectedSpeciality={selectedSpeciality}
                onReset={handleReset}
                onUpdateTerm={handleUpdateTerm}
                selectedTerm={selectedTerm}
              />
            </Cell>
            <Cell sizes={{ default: 1, desktop: 1 / 3 }}>
              <Cards>
                <Card>
                  <Icon>
                    <MdPlace size={72} />
                  </Icon>
                  <p>Découvrez les partenaires autour de vous !</p>
                  <Button
                    as={Link}
                    variant="primary"
                    size="full"
                    to="/pro/carte"
                  >
                    Carte
                  </Button>
                </Card>
                <Card>
                  <Icon>
                    <img src={renseignement} alt="" />
                  </Icon>
                  <p>Consultez les questions les plus fréquentes !</p>
                  <Button
                    as={Link}
                    to="/pro/questions-frequentes"
                    variant="primary"
                    size="full"
                  >
                    Questions fréquentes
                  </Button>
                </Card>
              </Cards>
            </Cell>
          </Grid>
        </Container>
      </Section>
      <Section>
        <Container>
          <Title>Actualités</Title>
          {actualities.map(({ node }, index) => (
            <ArticleCard
              actuality={node?.data}
              key={node?.uid}
              index={index}
              variant="pro"
            />
          ))}
          <Buttons>
            <Button app="pro" as={Link} to="/pro/actualites">
              Voir toutes les actualités
            </Button>
          </Buttons>
        </Container>
      </Section>
    </Layout>
  );
};

export const query = graphql`
  query ActualitiesProQuery {
    allPrismicActuality(
      limit: 5
      sort: { fields: first_publication_date, order: DESC }
      filter: { data: { target: { eq: "pro" } } }
    ) {
      edges {
        node {
          data {
            target
            title {
              richText
            }
            content {
              richText
            }
            thumbnail {
              alt
              url
            }
            kind
          }
        }
      }
    }
    allPrismicHandicap {
      edges {
        node {
          data {
            name {
              richText
              text
            }
          }
          uid
          id
          prismicId
        }
      }
    }
    allPrismicIssue {
      edges {
        node {
          data {
            name {
              richText
              text
            }
          }
          uid
          id
          prismicId
        }
      }
    }
    allPrismicSpecialty(filter: { data: { pro: { eq: true } } }) {
      edges {
        node {
          uid
          id
          prismicId
          data {
            name {
              richText
              text
            }
            pro
          }
        }
      }
    }
  }
`;

export default IndexPage;
