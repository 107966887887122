import React from 'react';
import styled, { css } from 'styled-components';
import { orderBy } from 'lodash';
import SelectCriteria from 'components/SelectCriteria';
import { Input, Label } from 'ui/forms';
import { Buttons, Button, FormField, media } from 'ui';
import { Link, navigate } from 'gatsby';

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.primary};
  border-radius: 8px;
  color: ${({ theme }) => theme.textOnPrimaryGradient};
  padding: ${({ theme }) => theme.spacing(2)};
  font-weight: 600;
  height: 100%;
  display: flex;
  flex-direction: column;

  > div + div {
    margin-top: ${({ theme }) => theme.spacing()};
  }

  ${media.tablet`
    ${({ variant }) =>
      variant === 'inline' &&
      css`
        flex-direction: row;
        > div + div {
          margin-top: 0;
          margin-left: ${({ theme }) => theme.spacing()};
        }
      `}
  `};
`;

const Subtitle = styled.div`
  font-size: 1.25rem;
  position: relative;

  ${({ alignment }) =>
    alignment === 'centered' &&
    `
      text-align: center;

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        width: 100%;
        border-bottom: 1px solid rgba(255, 255, 255, 0.8);
      }
  `}

  span {
    position: relative;
    padding-left: ${({ theme }) => theme.spacing()};
    padding-right: ${({ theme }) => theme.spacing()};
    display: inline-block;
    background-color: ${({ theme }) => theme.primary};
  }
`;

const Cell = styled.div`
  ${({ variant }) =>
    variant === 'inline' &&
    `
    flex: 2;
  `}
`;

const HomeForm = ({
  issues,
  variant,
  handicaps,
  onChange,
  onUpdateTerm,
  specialities,
  selectedTerm,
  selectedIssue,
  selectedHandicap,
  selectedSpeciality,
}) => {
  const getOptionsFromInput = input => {
    return orderBy(
      input.map(({ node }) => ({
        value: node?.prismicId,
        label: node?.data?.name?.text,
      })),
      'label',
      'asc',
    );
  };

  return (
    <Wrapper variant={variant}>
      <>
        {variant !== 'inline' && (
          <Subtitle>Votre recherche concerne :</Subtitle>
        )}
        <Cell variant={variant}>
          <FormField>
            <SelectCriteria
              label="Un handicap"
              placeholder="Sélectionnez un handicap"
              options={getOptionsFromInput(handicaps)}
              onChange={handicap => onChange({ kind: 'handicap', ...handicap })}
              variant="input"
              selectedValue={selectedHandicap}
            />
          </FormField>
        </Cell>
        <Subtitle alignment="centered">
          <span>OU</span>
        </Subtitle>
        <Cell variant={variant}>
          <FormField>
            <SelectCriteria
              label="Une problématique"
              placeholder="Sélectionnez une problématique"
              options={getOptionsFromInput(issues)}
              onChange={issue => onChange({ kind: 'issue', ...issue })}
              variant="input"
              selectedValue={selectedIssue}
            />
          </FormField>
        </Cell>
        <Subtitle alignment="centered">
          <span>OU</span>
        </Subtitle>
        <Cell variant={variant}>
          <FormField>
            <SelectCriteria
              label="Une spécialité"
              placeholder="Sélectionnez une spécialité"
              options={getOptionsFromInput(specialities)}
              onChange={speciality =>
                onChange({ kind: 'speciality', ...speciality })
              }
              variant="input"
              selectedValue={selectedSpeciality}
            />
          </FormField>
        </Cell>
      </>
      <Subtitle alignment="centered">
        <span>OU</span>
      </Subtitle>
      <Cell variant={variant}>
        <FormField>
          <Label>Recherche par mots-clés</Label>
          <Input
            value={selectedTerm}
            onChange={onUpdateTerm}
            placeholder="Recherchez un mot-clé"
            onKeyDown={e => {
              if (e.key === 'Enter') {
                navigate('/pro/recherche', {
                  state: {
                    selectedHandicap,
                    selectedIssue,
                    selectedSpeciality,
                    selectedTerm,
                  },
                });
              }
            }}
          />
        </FormField>
      </Cell>

      {variant !== 'inline' && (
        <>
          <Buttons>
            <Button
              variant="onPrimaryGradient"
              as={Link}
              to="/pro/recherche"
              state={{
                selectedHandicap,
                selectedIssue,
                selectedSpeciality,
                selectedTerm,
              }}
            >
              Rechercher
            </Button>
          </Buttons>
        </>
      )}
    </Wrapper>
  );
};

HomeForm.defaultProps = {
  values: {},
};

export default HomeForm;
