import React, { forwardRef, useRef } from 'react';
import styled, { css } from 'styled-components';
import { PopoverItem, PopoverItemButton, PopoverMenu } from 'ui/popover';
import { Label } from 'ui/forms';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { get } from 'lodash';
import Dropdown from './Dropdown';
import Dotdotdot from 'react-dotdotdot';
import { useWidget } from '../WidgetProvider';

const Trigger = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${({ theme }) => theme.background};
  color: ${({ theme }) => theme.text};
  border: 1px solid ${({ theme }) => theme.separator};
  box-sizing: border-box;
  border-radius: 8px;
  font-size: 1rem;
  height: 58px;
  padding: 0 ${({ theme }) => theme.spacing()};
  width: 100%;

  ${({ variant, theme }) =>
    variant === 'input' &&
    `
      background: ${({ theme }) => theme.background};
      padding: ${theme.spacing(0.25)} ${theme.spacing()};
      border-radius: 4px;
      color: inherit;
      font-size: 1rem;
      line-height: 1;
      width: 100%;
      height: 48px;

      &:focus,
      &:active {
        border-color: ${({ theme }) => theme.primary};
      }

      &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 30px white inset;
        color: inherit;
      }

      &:disabled {
        background-color: rgba(0, 40, 100, 0.04);
        cursor: not-allowed;
      }
  `}

  ${({ disabled }) =>
    Boolean(disabled) &&
    css`
      opacity: 0.5;
    `}

  svg {
    margin-left: ${({ theme }) => theme.spacing(0.5)};
  }
`;

const Value = styled(Dotdotdot)`
  ${({ isEmpty, theme }) => `
    &, +svg {
      color: ${isEmpty ? theme.textLight : theme.text};
    }
  `};
`;

const Select = forwardRef(
  (
    {
      label,
      placeholder,
      onChange,
      selectedValue,
      options,
      disabled,
      variant,
      wrapperStyle,
    },
    ref,
  ) => {
    const dropdownRef = useRef();
    const { fontName } = useWidget();
    const clamp = fontName === 'OpenDyslexic' ? 2 : 1;

    return (
      <>
        {Boolean(label) && <Label>{label}</Label>}

        <Dropdown
          ref={dropdownRef}
          wrapperStyle={wrapperStyle}
          trigger={
            <Trigger disabled={disabled} variant={variant}>
              <Value isEmpty={!Boolean(selectedValue)} clamp={clamp}>
                {get(selectedValue, 'label', placeholder)}
              </Value>
              <MdKeyboardArrowDown />
            </Trigger>
          }
        >
          <PopoverMenu>
            {options.map(({ value, label }, index) => (
              <PopoverItem key={index}>
                <PopoverItemButton
                  type="button"
                  onClick={() => {
                    onChange({ value, label });
                    dropdownRef.current.close();
                  }}
                  isActive={value === get(selectedValue, 'value')}
                >
                  {label}
                </PopoverItemButton>
              </PopoverItem>
            ))}
          </PopoverMenu>
        </Dropdown>
      </>
    );
  },
);

Select.defaultProps = {
  placeholder: '',
  options: [],
};

export default Select;
